import React, { type FC, useContext, useEffect, useState } from 'react'
import { Box, Card, CardContent, Typography, IconButton, Tooltip } from '@mui/material'
import ReportIcon from '@mui/icons-material/Report'
import WarningIcon from '@mui/icons-material/Warning'
import InfoIcon from '@mui/icons-material/Info'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import axios from 'axios'
import ConfigContext from '../ConfigContext/Config'

interface Notification {
  title: string
  message: string
  type: 'critical' | 'warning' | 'general'
  createdDate: string
}

const NotificationTray: FC<any> = () => {
  const [filter, setFilter] = useState<'critical' | 'warning' | 'general' | 'all'>('all')
  const [notifications, setNotifications] = useState<Notification[]>([])
  const { properties } = useContext(ConfigContext)

  useEffect(() => {
    axios.get(`${properties.baseUrls.campaign}/dynamo/notifications/${properties.id}`)
      .then(response => {
        setNotifications(response.data)
      })
      .catch(error => {
        console.error('Error fetching notifications:', error)
      })
  }, [])

  const filteredNotifications = notifications.filter(notification => filter === 'all' || notification.type === filter)
  // origin.id
  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Notifications</Typography>
        <Box>
          <Tooltip title="Critical">
            <IconButton onClick={() => { setFilter('critical') }} color={filter === 'critical' ? 'error' : 'default'}>
              <ReportIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Warning">
            <IconButton onClick={() => { setFilter('warning') }} color={filter === 'warning' ? 'warning' : 'default'}>
              <WarningIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="General">
            <IconButton onClick={() => { setFilter('general') }} color={filter === 'general' ? 'info' : 'default'}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="All">
            <IconButton onClick={() => { setFilter('all') }} color={filter === 'all' ? 'primary' : 'default'}>
              <AllInclusiveIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ height: 'calc(100% - 40px)', overflowY: 'auto' }}>
        {filteredNotifications.length > 0
          ? (
              // filteredNotifications.map((notification, index) => (
              //   <Box display="flex" justifyContent="space-between">
              //     <Box>
              //       <Typography variant="h6" align='left'>{notification.title}</Typography>
              //       <Typography variant="body2" align='left'>{notification.message}</Typography>
              //     </Box>
              //     <Typography variant="body2" color="textSecondary">
              //       {new Date(notification.createdDate).toLocaleString()}
              //     </Typography>
              //   </Box>
              // ))
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography variant="h6" align='left'>{filteredNotifications[0].title}</Typography>
                    <Typography variant="body2" align='left'>{filteredNotifications[0].message}</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {new Date(filteredNotifications[0].createdDate).toLocaleString()}
                  </Typography>
                </Box>
            )
          : (
          <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="body1" align='center'>No notifications</Typography>
          </CardContent>
        </Card>
            )}
      </Box>
    </Box>
  )
}

export default NotificationTray
